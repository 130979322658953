<template>
  <div class="header" :style="{ background: subjectColor }">
    <div class="logo">
      <div class="d-flex" v-if="menuType === 'card'">
        <div class="logoImg">
          <el-image fit="cover" class="elLogo" :src="logo" @click="handleCollapseChange" />
        </div>
        <span>{{ title }}</span>
      </div>
      <div class="d-flex" v-else-if="menuType === 'yuanqv'">
        <div class="logoImg">
          <el-image fit="cover" class="elLogo" :src="parkLogo" @click="handleCollapseChange" />
        </div>
        <span v-if="parkData.length == 0">{{ parkTitle }}</span>
        <el-dropdown v-else-if="parkData.length > 0" trigger="click" @command="parkCommandFun">
          <span class="el-dropdown-link">{{ parkTitle }}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(row, index) in parkData" :key="index" :command="row">{{ row.parkName }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="d-flex" v-else-if="menuType === 'shetuan'">
        <div class="logoImg">
          <el-image fit="cover" class="elLogo" :src="associationLogo" @click="handleCollapseChange" />
        </div>
        <span v-if="associationData.length == 0">{{ associationTitle }}</span>
        <el-dropdown v-else-if="associationData.length > 0" trigger="click" @command="associationCommandFun">
          <span class="el-dropdown-link">{{ associationTitle }}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(row, index) in associationData" :key="index" :command="row">
              {{ row.associationName }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="collapse-btn fs10" @click="handleCollapseChange">
      <i v-if="!collapse" class="el-icon-s-fold" style="color:#303133"></i>
      <i v-else class="el-icon-s-unfold" style="color:#303133"></i>
    </div>
    <div class="system-title menuType">
      <!-- <span v-if="menuType === 'card'" class="el-dropdown-link" @click="menuTypeFun('yuanqv')">园区管理</span>
      <span v-if="menuType === 'yuanqv'" @click="menuTypeFun('card')">工作台</span> -->
      <el-tabs v-model="menuType" v-if="showOtherEnd" @tab-click="menuTypeFun">
        <el-tab-pane label="CRM管理" name="card"></el-tab-pane>
        <el-tab-pane label="园区管理" name="yuanqv"></el-tab-pane>
        <el-tab-pane label="社团管理" name="shetuan"></el-tab-pane>
        <!-- <el-tab-pane label="政务管理" name="zhengwu" disabled></el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip effect="dark" :content="fullScreen?`取消全屏`:`全屏`" placement="bottom">
            <i class="el-icon-rank cA1"></i>
          </el-tooltip>
        </div>
        <div class="user-avator">
          <img :src="userInfo.avatarUrl" />
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link" style="color:rgba(0, 0, 0, 0.65);">
            {{ userInfo.userName + '-' }}{{ userInfo.roleId ? 'boss' : '成员' }}
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="row.cardId" v-for="(row, index) in cardData" :key="index">{{ row.companyName }}
            </el-dropdown-item>
            <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../service/bus";
import utils from "../../utils/index";
import { selectMemberOptionList } from "@/api/login";
import {
  getMyCardList,
  switchCard,
  loginAgain
} from "@/api/oemConfig.js";
import { fetchCompany } from "@/api/companyManage.js";
import { getParkManageList } from "@/api/parkManagement"
import { getAssociationManageList } from "@/api/association";
export default {
  name: "commonHeader",
  data() {
    return {
      collapse: false,
      fullScreen: false,
      userInfo: this.$store.state.loginRoot.userInfo,
      title: '',
      logo: '',
      icon: '',
      menuType: "card",
      parkData: [],//用于园区切换
      parkLogo: "",
      parkTitle: "",
      associationData: [],//用于社团切换
      associationLogo: "",
      associationTitle: "",
      cardData: [],//用于公司切换
      showOtherEnd: false
    };
  },
  computed: {
    /**@method 监听数据园区 */
    watchCheckedPark() {
      return this.$store.state.checkedPark
    },
    /**@method 监听数据社团 */
    watchAssociation() {
      return this.$store.state.checkedAssociation
    },
    /**@method 监听数据园区 */
    watchParkData() {
      return this.$store.state.myParkData
    },
    /**@method 监听数据社团 */
    watchAssociationData() {
      return this.$store.state.myAssociationData
    },
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor
    }
  },
  watch: {
    /**@method 监听园区列表 */
    watchParkData(data) {
      this.parkData = data ? data : [];
    },
    /**@method 监听社团列表 */
    watchAssociationData(data) {
      this.associationData = data ? data : [];
    },
    /**@method 监听数据园区 */
    watchCheckedPark(row) {
      this.parkLogo = row ? row.parkImg : this.logo;
      this.parkTitle = row ? row.parkName : this.title;
    },
    /**@method 监听数据社团 */
    watchAssociation(row) {
     //-console.log(row);
      this.associationLogo = row ? row.associationImg : this.logo;
      this.associationTitle = row ? row.associationName : this.title;
    }
  },
  mounted() {
    this.menuType = sessionStorage.getItem('menuType') ? sessionStorage.getItem('menuType') : "card";
    // this.menuType = "card";
    sessionStorage.setItem('menuType', this.menuType);
    this.$store.commit("setMenuType", this.menuType);
    this.setOption().then(() => {//配置是否显示 分销会员菜单
      this.getParkManageList();//配置园区数据
      this.getAssociationManageList();//配置社团数据
    });

    this.initSet();//配置公司数据
    this.getMyCardList();//获取管理员关联的公司
    this.getCompany();
  },
  methods: {
    getCompany() {
      // let userInfo = JSON.parse(localStorage.getItem('info'));
      // if(userInfo.roleId){
      //   this.showOtherEnd=true;
      //   return ;
      // }
      return fetchCompany({}).then(res => {
        if (res.data.companyType === 2) {
          this.showOtherEnd = true;
        }
      })
    },
    /**@method 获取名片数据 */
    getMyCardList() {
      getMyCardList({}).then(res => {
        try {
          this.cardData = res.data;
        } catch (err) {
          this.cardData = [];
        }
      })
    },
    /**@method 切换园区
     * @row 园区数据
     */
    parkCommandFun(row) {
      sessionStorage.setItem("checkedPark", JSON.stringify(row));
      this.$store.commit("setCheckedPark", row)
      this.parkLogo = row.parkImg ? row.parkImg : this.logo;
      this.parkTitle = row.parkName;
    },
    /**@method 切换社团
     * @row 社团数据
     */
    associationCommandFun(row) {
      sessionStorage.setItem("checkedAssociation", JSON.stringify(row));
      this.$store.commit("setCheckedAssociation", row)
      this.associationLogo = row.associationImg ? row.associationImg : this.logo;
      this.associationTitle = row.associationName;
    },
    /**@method 获取社团数据 */
    async getAssociationManageList() {
      const association = sessionStorage.getItem('checkedAssociation');//获取缓存中的社团数据
      if (association) {//storage缓存有社团数据直接保存到vuex缓存中
        this.associationLogo = JSON.parse(association).associationImg;
        this.associationTitle = JSON.parse(association).associationName;
        this.$store.commit("setCheckedAssociation", JSON.parse(association))
      }
      let res = await getAssociationManageList({
        companyId: this.userInfo.companyId,
        noParent: 0
      })//获取社团数据
      try {
        this.associationData = res.data ? res.data : [];
        if (this.associationData.length > 0) {//有社团列表直接保存到vuex缓存中
          this.$store.commit("setMyAssociationData", this.associationData)
          if (!association) {//storage缓存没有时初始化标题
            this.associationLogo = this.associationData[0].associationImg;
            this.associationTitle = this.associationData[0].associationName;
            sessionStorage.setItem("checkedAssociation", JSON.stringify(this.associationData[0]));
            this.$store.commit("setCheckedAssociation", this.associationData[0])
          }
        } else {
          this.associationData = [];
          this.associationLogo = this.logo;
          this.associationTitle = this.title;
          this.$store.commit("setMyAssociationData", [])
          this.$store.commit("setCheckedAssociation", null)
          sessionStorage.removeItem('checkedAssociation')
        }
      } catch (err) {
        //-console.log(err);
        this.associationData = [];
        this.associationLogo = this.logo;
        this.associationTitle = this.title;
        this.$store.commit("setMyAssociationData", [])
        this.$store.commit("setCheckedAssociation", null)
        sessionStorage.removeItem('checkedAssociation')
      }
    },
    /**
     * @method 获取园区数据
     * 缓存中有园区数据使用缓存，无园区数据使用列表第一条
     */
    async getParkManageList() {
      const park = sessionStorage.getItem('checkedPark');
      if (park) {
        this.parkLogo = JSON.parse(park).parkImg ? JSON.parse(park).parkImg : this.logo;
        this.parkTitle = JSON.parse(park).parkName;
        this.$store.commit("setCheckedPark", JSON.parse(park))
      }
      let res = await getParkManageList({ isPage: 0 })
      try {
        this.parkData = res.data ? res.data : [];
        if (this.parkData.length > 0) {
          this.$store.commit("setMyParkData", this.parkData)
          if (!park) {
            this.parkLogo = this.parkData[0].parkImg ? this.parkData[0].parkImg : this.logo;
            this.parkTitle = this.parkData[0].parkName;
            sessionStorage.setItem("checkedPark", JSON.stringify(this.parkData[0]));
            this.$store.commit("setCheckedPark", this.parkData[0])
          }
        } else {
          this.parkData = [];
          this.parkLogo = this.logo;
          this.parkTitle = this.title;
          this.$store.commit("setMyParkData", [])
          this.$store.commit("setCheckedPark", null)
          sessionStorage.removeItem('checkedPark')
        }
      } catch (err) {
        //-console.log(err);
        this.parkData = [];
        this.parkLogo = this.logo;
        this.parkTitle = this.title;
        this.$store.commit("setMyParkData", [])
        this.$store.commit("setCheckedPark", null)
        sessionStorage.removeItem('checkedPark')
      }
    },
    async initSet() {
      // if (document.domain != "localhost" && document.domain != "crm.yuanqi366.com") {
      //   const oemConfig = await queryOemDomain({ oemDomain: document.domain });
      //   if (!oemConfig) {
      //     this.$message.error('oem配置错误,请联系售后');
      //     return;
      //   }
      //   this.setOemConfig(oemConfig);
      // } else {
      this.setOemConfig();
      // }

    },
    menuTypeFun() {//切换到园区后台
      sessionStorage.setItem('menuType', this.menuType);
      this.$store.commit("setMenuType", this.menuType);
      this.$router.push("/dashboard");
    },
    /**@method 配置公司logo、公司名 */
    setOemConfig(oemConfig) {
      //-console.log(oemConfig)
      //查询所有Oem配置 crm.yuanqi366.com localhost 本地调试
      //-console.log("domain:", document.domain);
      //获取icon
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      if (oemConfig) {
        link.href = oemConfig.data.iconLogo;
        document.getElementsByTagName('head')[0].appendChild(link);
        //获取网页标题
        document.title = oemConfig.data.oemName;
        this.title = oemConfig.data.oemName;
        this.logo = oemConfig.data.oemLogo;
      } else {
        let info = JSON.parse(localStorage.getItem('info'))
        link.href = "http://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/icon/favicon.ico";
        document.getElementsByTagName('head')[0].appendChild(link);
        //获取网页标题
        document.title = info.companyName ? info.companyName : "园企汇智能CRM"; //"园企汇智能CRM";
        this.title = info.companyName ? info.companyName : "园企汇智能CRM";//"园企汇智能CRM";
        this.logo = info.companyLogo ? info.companyLogo : "http://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/icon/yimai.png";//"http://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/icon/yimai.png";
      }
    },
    // 全屏
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      this.fullScreen = !this.fullScreen;
    },
    setOption() {
      sessionStorage.setItem("showParkMember", false);
      sessionStorage.setItem("showAssociationMember", false);
      return selectMemberOptionList({}).then((optionRES) => {
        if (optionRES.code === 200) {
          for (let row of optionRES.data) {
            if (row.accountType === 8) {
              if ("isUse" in row && row.isUse === 1) {
                sessionStorage.setItem("showParkMember", true);
              } else {
                sessionStorage.setItem("showParkMember", false);
              }
            } else if (row.accountType === 9) {
              if ("isUse" in row && row.isUse === 1) {
                sessionStorage.setItem("showAssociationMember", true);
              } else {
                sessionStorage.setItem("showAssociationMember", false);
              }
            }
          }
        } else {
          sessionStorage.setItem("showParkMember", false);
          sessionStorage.setItem("showAssociationMember", false);
        }
        // open();
      }).catch(err => {
        sessionStorage.setItem("showParkMember", false);
        sessionStorage.setItem("showAssociationMember", false);
        // open();
      })
    },
    // 下拉菜单选择
    handleCommand(commond) {
      if (commond === "logout") {
        utils.clearLoginInfo()
      } else {
        switchCard({ cardId: commond }).then(() => {
          loginAgain({}).then(res => {
            localStorage.setItem("info", JSON.stringify(res.data))
            sessionStorage.removeItem("checkedPark")
            sessionStorage.removeItem("checkedAssociation")
            localStorage.removeItem("GPTMessage")
            localStorage.setItem("token", res.data.token);
            sessionStorage.setItem('menuType', 'card')
            this.$router.push("/dashboard");
            this.$router.go(0)
          })
        })
      }
    },

    // 控制折叠面板
    handleCollapseChange() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
  },
};
</script>

<style>
.menuType .el-tabs__nav {
  height: 48px !important;
}

.menuType .el-tabs__header {
  height: 48px !important;
}

.menuType .el-tabs__item {
  height: 48px !important;
  line-height: 48px !important;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  font-size: 22px;
  background-color: #6190e8;
  color: #ffffff;
  box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
  z-index: 2;
  /* border-bottom: 1px solid #eee; */
}

/* .menuType {
  position: absolute;
  height: 48px;
  left: 350px
} */

.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 48px;
  color: #ffffff;
  margin-left: 23px;
}

.header .system-title {
  float: left;
  line-height: 48px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  cursor: pointer;
}

.header .logo {
  float: left;
  line-height: 48px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  margin-left: 5px;
}

.header .logo .logoImg {
  height: 60px;
  width: 60px;
  cursor: pointer;
  border-radius: 10px;
  background: #fff;
  padding: 5px;
  box-shadow: 0px 2px 6px #cdcdcd;
  margin-right: 10px;
}

.header .logo .elLogo {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.header .logo img {
  vertical-align: middle;
  margin-right: 10px;
}

.header .logo span,
.header .system-title span {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 700;
  color: #3a3a3a;
}

.header .system-title span {
  font-size: 20px;
  font-weight: 400;
}

.header-right {
  float: right;
  padding-right: 20px;
}

.header-user-con {
  display: flex;
  height: 48px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #999;
}

.btn-bell .el-icon-bell {
  color: #999;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 10px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
